<template>
  <el-dialog v-if="dataInfo"
             :title="title"
             @closed="_closed"
             :visible.sync="visible"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             custom-class="top5"
             top="5vh"
             width="80%">
    <el-form ref="dataInfo"
             :model="dataInfo"
             :rules="dataRules"
             label-width="120px">
      <el-row class="twice">
        <el-form-item label="车牌号"
                      prop="carNo">
          <el-input v-model="dataInfo.carNo"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="选择油卡"
                      prop="cardNo">
          <el-input v-model="dataInfo.cardNo"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="加油时间"
                      prop="useTime">
          <l-datepicker v-model="dataInfo.useTime"></l-datepicker>
        </el-form-item>
        <el-form-item label="使用金额"
                      prop="amount">
          <el-input v-model="dataInfo.amount"
                    autocomplete="off">
            <span slot="suffix">元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="加油前公里数"
                      prop="lastMileage">
          <el-input v-model="dataInfo.lastMileage"
                    autocomplete="off">
            <span slot="suffix">公里</span>
          </el-input>
        </el-form-item>
        <el-form-item label="加油后公里数"
                      prop="mileage">
          <el-input v-model="dataInfo.mileage"
                    autocomplete="off">
            <span slot="suffix">公里</span>
          </el-input>
        </el-form-item>
        <el-form-item label="油号"
                      prop="oilNo">
          <el-radio-group v-model="dataInfo.oilNo">
            <el-radio-button label="92#">92#</el-radio-button>
            <el-radio-button label="95#">95#</el-radio-button>
            <el-radio-button label="0#">0#(柴油)</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="备注说明"
                      prop="desc">
          <el-input type="textarea"
                    v-model="dataInfo.desc"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div slot="footer"
         class="dialog-footer">
      <el-button @click="_cancle">取 消</el-button>
      <el-button type="primary"
                 @click="save">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data () {
    return {
      title: '', // 内置参数,误删
      visible: true, // 内置参数,误删
      dataInfo: null,
      activeName: 'base',
      dataRules: {
        roomName: [{ required: true, trigger: 'blur', message: '会议室名称不能为空' }],
        place: [{ required: true, trigger: 'blur', message: '会议室位置不能为空' }]
      }
    }
  },
  methods: {
    _closed () {
      // 底层内置方法 误删
      this.onclosed()
    },
    _cancle () {
      // 底层内置方法 误删
      this.visible = false
      this.cancle && this.cancle()
    },
    uploaded (res) {
      Object.assign(this.dataInfo, {
        imgUrlList: res
      })
      // this.$set(this.dataInfo)
    },
    save () {
      this.$refs.dataInfo.validate(async valid => {
        if (valid) {
          await this.$lgh.post('api/enterprise/car/oilrecord/addoredit', this.dataInfo)
          this.visible = false
          this.onsuccess && this.onsuccess(this.dataInfo)
        }
      })
    }
  }
}
</script>

<style></style>
